import React, { useState, useContext, useEffect } from 'react';
import SpinnerLoader from '../layout/spinner-loader';
import { UserContext } from '../../contexts/user-context';
import { useForm } from "react-hook-form";
import { FilePath } from '../../services/utilities';
import apiPreferredCustomer from '../../services/api-preferred-customer';
import apiTools from '../../services/api-tools';
import '../preferred-customer/profile.scss';
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { Link } from 'react-router-dom';

const AddYourLogoForm = ({setIncludeLogo, modal}) => {
    const { pcUser, getUserProfileSilently } = useContext(UserContext);
    const { register, watch } = useForm();
    const [dropzone, setDropzone] = useState(null);
    const [logoPaidFor, setLogoPaidFor] = useState(false);
    const [loading, setLoading] = useState(false);
    const watchInclude = watch("includeLogo");

    useEffect(() => {
        setIncludeLogo && setIncludeLogo(watchInclude);
    }, [watchInclude]);

    useEffect(() => {
        if (pcUser == null) return;
        if (!dropzone) { 
            var authorizeHeader = "";
            var accessToken = localStorage.getItem("pcuser.accessToken");
            if (accessToken) {
                authorizeHeader = `Bearer ${accessToken}`;
            }
            var headers = {
                'Authorization': authorizeHeader,
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_PREFERREDCUSTOMER_SUBSCRIPTION_KEY
            };
            var dz = new Dropzone("div#logo", {
                url: process.env.REACT_APP_API_PREFERREDCUSTOMER_BASE_URL + '/upload-logo',
                createImageThumbnails: true,
                maxFiles: 1,
                headers: headers,
                maxFilesize: 15000000,
                addRemoveLinks: true,
                dictDefaultMessage: "Click or drop logo file here to upload",
                renameFile: truncateLogoName
            });
            dz.on("removedfile", async () => { 
                await apiPreferredCustomer.post('/remove-logo').then(() => getUserProfileSilently());
                dz.options.maxFiles = 1;
                setIncludeLogo && setIncludeLogo(false);
            })
            if (pcUser.logo) { 
                var existingLogo = {name: pcUser.logo, size: 12345, accepted: true}
                dz.displayExistingFile(existingLogo, FilePath('/preferredcustomerlogos/' + pcUser.logo, ), null, '*');
                dz.options.maxFiles = 0;
            }

            dz.on("addedfile", async (file) => {
                var fileName =  truncateLogoName(file)
                await apiPreferredCustomer.post(`/change-logo-on-account/${pcUser.preferredCustomerId + '-' + fileName}`).then(() => getUserProfileSilently());
                dz.options.maxFiles = 0;
            })
            setDropzone(dz);
        }
        const getLogoPaidFor = async () => {
            await apiTools.get(`feature-paid-for/19`).then((paidResponse) => { 
                setLogoPaidFor(paidResponse.data);
                setLoading(false);
            });

        }
        getLogoPaidFor();
    }, [pcUser]);

    const truncateLogoName = (file) => {
        if (file.name.length <= 50) return file.name;
        var truncatedName = file.name.substring(0, 50);
        var extension = file.name.split('.').pop();
        return truncatedName + '.' + extension;
    }
              
    return (
        <>
            {loading && <SpinnerLoader/>}
            <span className={logoPaidFor ? "" : "d-none"}>
                {setIncludeLogo && <div className="row">
                    <div className="col-9 mt-2">
                        <label className="fw-bold">Add your logo when downloading and emailing?:</label>
                    </div>
                    <div className="col-3 mt-2">
                        <label htmlFor="includeLogo"><input type="checkbox" id="includeLogo" disabled={!pcUser.logo} {...register("includeLogo")} /> Yes</label>
                    </div>
                </div>}
                <div className="row py-2">
                    <div className="col">
                        <div id="logo" className="dropzone dropzone-previews border"/>
                    </div>
                </div>
            </span>
            <span className={logoPaidFor ? "d-none" : ""}>
                <div className="row">
                    <div className="col">
                        You have not paid for logo access. Please <span data-bs-dismiss={modal ? "modal" : ""}><Link to="/preferred-customer/feature-payment">click here to subscribe.</Link></span>
                    </div>
                </div>
            </span>
        </>
    );
}

export default AddYourLogoForm;