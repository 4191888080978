import axios from 'axios';
import { msalInstance } from '../index';
import { tokenRequest } from '../auth-config';
import { BuildAxiosClientErrorDetail } from './utilities';
import apiPublic from './api-public';

const apiSentryDynamicsArcGIS = axios.create({
    baseURL: process.env.REACT_APP_API_TOOLS_BASE_URL + "map-data/",
});
apiSentryDynamicsArcGIS.defaults.headers.common['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_API_TOOLS_SUBSCRIPTION_KEY;


apiSentryDynamicsArcGIS.interceptors.request.use(
    request => {
        var authorizeHeader = "";
        var accessToken = localStorage.getItem("pcuser.accessToken");
        if (accessToken) {
            authorizeHeader = `Bearer ${accessToken}`;
        }
        request.headers = { ...request.headers, 'Authorization': authorizeHeader };
        request.url = '?requestPath=' + encodeURIComponent(request.url);
        return request;
    },
    error => {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);


apiSentryDynamicsArcGIS.interceptors.response.use(
    response => {
        // Return a successful response back to the calling service
        return response;
    },
    error => {
        if (error.response.status === 401) { // Unauthorized
            const account = msalInstance.getActiveAccount();
            if (!account) {
                localStorage.removeItem("pcuser");
                localStorage.removeItem("pcuser.accessToken");
                if (error.config.noErrorHandling) {
                    return Promise.reject("No active account");
                }
                else {
                    window.location.href = '/preferred-customer/account-required';
                }
            }
            // Get new updated access token and try again
            return msalInstance.acquireTokenSilent({
                ...tokenRequest,
                account: account
            }).then((authResult) => {
                var newAccessToken = authResult.accessToken;
                localStorage.setItem("pcuser.accessToken", newAccessToken);
                error.config.headers.Authorization = `Bearer ${newAccessToken}`;
                return apiSentryDynamicsArcGIS.request(error.config);
            }).catch((retryerror) => {
                console.log("retryerror:", retryerror);
                localStorage.removeItem("pcuser");
                localStorage.removeItem("pcuser.accessToken");
                if (error.config && error.config.noErrorHandling) {
                    return Promise.reject("No active account");
                }
                else {
                    window.location.href = '/preferred-customer/account-required';
                }
            });
        }

        if (error.response.status !== 401 && error.response.status !== 0 && !error.config.noErrorHandling) {
            const selectedBrand = JSON.parse(localStorage.getItem("selectedBrand"));
            const errObj = { ...BuildAxiosClientErrorDetail(error), brand: selectedBrand };
            return apiPublic.post(`/log-error`, errObj, { noErrorHandling: true })
                .finally(() => {
                    window.location.href = '/oops';
                });
        }

        return new Promise((resolve, reject) => {
            reject(error.response);
        });
    }
);

export default apiSentryDynamicsArcGIS;