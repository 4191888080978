import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import apiTools from '../../../services/api-tools';
import SpinnerLoader from '../../layout/spinner-loader';
import { EventTrack, GetEmailRegex, GetMultipleEmailRegex } from '../../../services/utilities';

const ClosingCostCalcEmail = ({emailFrom, selectedBrand, htmlForPdf, propAddress }) => {
    const [emailSending, setEmailSending] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        setEmailSending(true);
        var fromAddress = (emailFrom) ? emailFrom : data.from;
        const emailCCCalc = {
            to: data.to,
            from: fromAddress,
            notes: data.notes,
            selectedBrand: selectedBrand,
            htmlForPdf: htmlForPdf,
            propAddress: propAddress
        }
        const sendEmail = async () => {
            await apiTools.post(`/closing-cost-calc/email`, emailCCCalc)
                .then((response) => {
                    setEmailSent(true);
                    setEmailSending(false);
                });
            EventTrack("Closing Cost Quote", "Email", "Link");
        }
        sendEmail();
    }

    return (
        <div className="modal fade" id="emailClosingCostQuoteModal" tabIndex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="emailModalLabel">Email Quote</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {!emailSending &&
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {emailSent ? <p>Thank you, your email has been sent.</p> : <div className="row">
                                    <div className="col-12 mt-2">
                                        <label className="fw-bold required">From:</label>
                                    </div>
                                    <div className="col-12 mt-2">
                                    {(emailFrom) ? emailFrom : <input className="form-control" id="from" {...register("from", { required: true, pattern: GetEmailRegex() })} />}
                                    {errors?.from?.type === "required" && <small className="text-danger">This field is required</small>}
                                    {errors?.from?.type === "pattern" && <small className="text-danger">Invalid email address</small>}
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label className="fw-bold required" htmlFor="emailTo">To address(es):</label>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <input type="text" className="form-control" id="emailTo" {...register("to", { required: true, pattern: GetMultipleEmailRegex() })} />
                                        <small>Separate multiple addresses with a comma (,).</small>
                                        {errors?.to?.type === "required" && <small className="text-danger"><br/>This field is required</small>}
                                        {errors?.to?.type === "pattern" && <small className="text-danger"><br/>Invalid email address(es)</small>}
                                </div>
                                <div className="col-12 mt-2">
                                    <label className="fw-bold" htmlFor="note">Notes:</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <textarea id="notes" rows="3" className="form-control" {...register("notes")} ></textarea>
                                </div>
                                </div>}

                                <div className="modal-footer">
                                    {!emailSent && <button type="submit" className="btn btn-primary m-auto">Send Email</button>}
                                    <span onClick={() => setEmailSent(false)}><button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">{emailSent ? "Close" : "Cancel"}</button></span>
                                </div>
                            </form>
                        }
                        {emailSending && <SpinnerLoader /> }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClosingCostCalcEmail;