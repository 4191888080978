import React, { useContext, useState, useEffect, useRef } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { UserContext } from '../../contexts/user-context';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdOutlineSearch, MdOutlineHistory, MdOutlineHome } from 'react-icons/md'
import apiTools from '../../services/api-tools';
import "./propertyone.scss"
import SpinnerLoader from '../layout/spinner-loader';
import SearchResult from './search-result';
import ResultsMap from './results-map';
import RecentProperties from './recent-properties';
import ParcelInfo from './parcel-info';
import { bootstrap } from '../../app';
import { toTitleCase } from '../../services/utilities';
import PropertiesNearMe from './properties-near-me';
import StreetMap from './street-map';
import LocatorMaps from './locator-maps';
import Deeds from './deeds';
import CCRsPlatsHoa from './ccrs-plats-hoa';
import AddMyLogoTab from './add-my-logo-tab';
import DownloadEmailProfile from './download-email-profile';
import QuickProfileRequest from './quick-profile-request';
import { DateTime } from 'luxon';
import { FilePath, EventTrack } from '../../services/utilities';
import BannerAd from '../layout/banner-ad';
import ExploreMore from './explore-more';
import CountySelector from '../layout/county-selector';

const PropertyOne = () => {
    const { selectedBrand } = useContext(BrandContext);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
    const { loadingUser, pcUser, activeRegion, logActivity } = useContext(UserContext);
    const [loadingSearchResults, setLoadingSearchResults] = useState(false);
    const [loadingMoreResults, setLoadingMoreResults] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsOverflow, setSearchResultsOverflow] = useState([]);
    const [searchResultsTotalCount, setSearchResultsTotalCount] = useState(0);
    const [searchResultsCurrentCount, setSearchResultsCurrentCount] = useState(0);
    const [searchResultsBatch, setSearchResultsBatch] = useState(0);
    const [searchCriteria, setSearchCriteria] = useState(null);
    const [reloadSelectedProperty, setReloadSelectedProperty] = useState(false);
    const emptyP1PropertyData = {
        owner: null, parcelId: null, address: null, subdivision: null, legalDescription: null, propertyType: null, latitude: null, longitude: null, wsWalkScore: null, wsBikeScore: null, wsTransitScore: null, 
    };
    const [selectedPropertyP1Data, setSelectedPropertyP1Data] = useState(emptyP1PropertyData);

    const [expandedProperty, setExpandedPropertyState] = useState(null);
    const [nearbySearchResults, setNearbySearchResults] = useState([]);
    const [resultsForMap, setResultsForMap] = useState([]);
    const [nearbySearchResultsTotalCount, setNearbySearchResultsTotalCount] = useState(0);
    const [hasP1Gold, setHasP1Gold] = useState(false);
    const [parcelHtml, setParcelHtml] = useState(null);
    const [folderGroupOpen, setFolderGroupOpen] = useState(-1);
    const [selectedCounty, setSelectedCounty] = useState(null);
    const [ccrState, setCcrState] = useState({ccrs: [], loading: false});
    const [deedState, setDeedState] = useState({ deeds: [], loading: false });

    const [aerialMap, setAerialMap] = useState(null);
    const [streetLocatorMap, setStreetLocatorMap] = useState(null);
    const [platMap, setPlatMap] = useState(null);
    const [streetMapAvailable, setStreetMapAvailable] = useState(false);
    const [directions, setDirections] = useState(null);
    const [position, setPosition] = useState(null);
    const [originAddress, setOriginAddress] = useState(null);
    const parcelInfoTab = useRef(null);
    const requestProfileTab = useRef(null);
    const SearchBody = useRef(null);
    const RecentPropertiesPanel = useRef(null);
    const SearchResultsBody = useRef(null);
    const OverLimitModal = useRef();

    useEffect(() => {
        if (!loadingUser && activeRegion) {
            setValue('searchCriteria', '');
            setNearbySearchResultsTotalCount(0);
            setNearbySearchResults([]);
            setSearchResultsBatch(0);
            setSelectedProperty(null);
        }
    }, [loadingUser, activeRegion]);

    const onSubmit = (searchCriteria) => {
        setNearbySearchResultsTotalCount(0);
        setNearbySearchResults([]);
        setSearchResultsBatch(0);
        setSelectedProperty(null);
        setSearchCriteria(searchCriteria);
        setLoadingSearchResults(true);
        getSearchResults(selectedCounty.state, selectedCounty.countyValue, searchCriteria.searchType, searchCriteria.searchCriteria)
            .then(() => {
                new bootstrap.Collapse(SearchResultsBody.current, { toggle: false }).show();
            });
        logActivity("PropertyOne - Search Performed", `State: ${selectedCounty.state} \nCounty: ${selectedCounty.county} \nSearch Type: ${searchCriteria.searchType} \nSearched For: ${searchCriteria.searchCriteria}`);
        EventTrack("PropertyOne", "Search", searchCriteria.searchType);
    }

    const getSearchResults = async (state, county, searchType, searchCriteria) => {
        apiTools.get(`/property-search?state=${state}&county=${county}&searchType=${searchType}&value=${searchCriteria}&batch=0`)
            .then((response) => {
                if (response.data.currentCount > 10) {
                    setSearchResults(response.data.records.slice(0, 10));
                    setSearchResultsOverflow(response.data.records.slice(10, response.data.currentCount))
                    setSearchResultsCurrentCount(10);
                    setResultsForMap(response.data.records.slice(0, 10));
                } else {
                    setSearchResults(response.data.records);
                    setResultsForMap(response.data.records);
                    setSearchResultsCurrentCount(response.data.currentCount || 0);
                }
                setSearchResultsTotalCount(response.data.totalCount > 150 ? 150 : response.data.totalCount || 0);
                if (response.data.totalCount === 1) {
                    setExpandedProperty(response.data.records[0]);
                    getNearbyResults(state, county, response.data.records[0]);
                    new bootstrap.Collapse(SearchBody.current, { toggle: false }).hide();
                    new bootstrap.Collapse(RecentPropertiesPanel.current, { toggle: false }).hide();
                } else if (response.data.totalCount > 0) {
                    setExpandedProperty(response.data.records[0]);
                    new bootstrap.Collapse(SearchBody.current, { toggle: false }).hide();
                    new bootstrap.Collapse(RecentPropertiesPanel.current, { toggle: false }).hide();
                    setLoadingSearchResults(false);
                } else {
                    setLoadingSearchResults(false);
                }
            });
    }

    function setExpandedProperty(prop) {
        setExpandedPropertyState(prop);
        var expandedPropDiv = document.getElementById(prop.parcelId);
        expandedPropDiv && expandedPropDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

    }

    function loadMoreResults() {
        setLoadingMoreResults(true);
        if (searchResultsOverflow.length > 0) {
            if (searchResultsOverflow.length > 10) {
                setSearchResults(searchResults.concat(searchResultsOverflow.slice(0, 10)));
                setResultsForMap(searchResults.concat(searchResultsOverflow.slice(0, 10)));
                setSearchResultsCurrentCount(searchResultsCurrentCount + 10);
                setSearchResultsOverflow(searchResultsOverflow.slice(10, searchResultsOverflow.length));
            } else {
                setSearchResults(searchResults.concat(searchResultsOverflow));
                setResultsForMap(searchResults.concat(searchResultsOverflow));
                setSearchResultsCurrentCount(searchResultsCurrentCount + searchResultsOverflow.length);
                setSearchResultsOverflow([])
            }
            setLoadingMoreResults(false);
        } else {
            apiTools.get(`/property-search?state=${selectedCounty.state}&county=${selectedCounty.countyValue}&searchType=${searchCriteria.searchType}&value=${searchCriteria.searchCriteria}&batch=${searchResultsBatch + 1}`)
            .then((response) => {
                setSearchResultsBatch(searchResultsBatch + 1);
                setSearchResults(searchResults.concat(response.data.records.slice(0,10)));
                setResultsForMap(searchResults.concat(response.data.records.slice(0,10)));
                setSearchResultsOverflow(response.data.records.slice(10,response.data.currentCount))
                setSearchResultsCurrentCount(searchResultsCurrentCount + 10);
                setLoadingMoreResults(false);
            });
        }
    }

    const getNearbyResults = (state, county, originalResult) => {
        clearPropertyData();
        apiTools.get(`/radius-search?state=${state}&county=${county}&parcelId=${originalResult.parcelId}&distance=500&max=10&batch=0`)
            .then((response) => {
                setNearbySearchResults(response.data.records);
                setNearbySearchResultsTotalCount(response.data.currentCount);
                response.data.records.push(originalResult);
                setResultsForMap(response.data.records);
                setLoadingSearchResults(false);
            });
    };

    const logPropertyActivity = (result) => {
        var propAddress = "";
        if (result.siteAddress != null) {
            propAddress = result.siteAddress + ((result.siteCity && result.siteCity !== 'null') ? ", " + result.siteCity : "");
        } else {
            propAddress = "To Be Determined";
        }
        
        const logpropertyvisit = async () => {
            await apiTools.post(`/log-recent-props/${activeRegion.regionId}`, { county: selectedCounty.county, parcelId: result.parcelId, address: propAddress, owner: result.ownerName }).then((response) => {
            });
            logActivity("PropertyOne Profile", `${propAddress} \nCounty: ${selectedCounty.county}, \nParcelID: ${result.parcelId}`);
            EventTrack("PropertyOne", "Profile Created", "From Recents");
        }
        logpropertyvisit();
    }

    const onRecentlySelectedProperty = (propSelected, propSelectedCounty, propSelectedState) => {
        clearPropertyData();
        setSelectedProperty(propSelected);
        setSelectedCounty(propSelectedCounty);
        syncSearchResults(propSelected, propSelectedCounty.countyValue, propSelectedState);
        logPropertyActivity(propSelected);
        showParcelInfoTab();
        new bootstrap.Collapse(SearchBody.current, { toggle: false }).hide();
        new bootstrap.Collapse(RecentPropertiesPanel.current, { toggle: false }).hide();
    }

    const syncSearchResults = (prop, county, state) => {
        setSearchResultsBatch(0);
        setValue("county", county);
        setValue("searchType", "parcelId");
        setValue("searchCriteria", prop.parcelId);
        setSearchCriteria(getValues());
        setLoadingSearchResults(true);
        getSearchResults(state, county, getValues("searchType"), getValues("searchCriteria"))
            .then(() => {
                new bootstrap.Collapse(SearchResultsBody.current, { toggle: false }).hide();
            });
    }

    const checkIsOverLimit = async () => {
        return apiTools.get(`/get-under-propertyone-limit`).then((response) => {
            setHasP1Gold(response.data[1] === 300)
            if (response.data[0] > response.data[1]) {
                var modal = new bootstrap.Modal(OverLimitModal.current, {});
                modal.show();
                return true;
            } else {
                return false;
            }
        });
    }

    const onSelectProperty = (result) => {
        clearPropertyData();
        checkIsOverLimit()
            .then((overLimit) => {
                if (!overLimit) {
                    setSelectedProperty(result);
                    logPropertyActivity(result);
                    toggleSearchResults();
                    showParcelInfoTab();
                }
            });
    }

    const showParcelInfoTab = () => {
        if (!parcelInfoTab.current) return;
        var tab = new bootstrap.Tab(parcelInfoTab.current)
        tab.show()
    }

    const showQuickProfileRequestTab = () => {
        if (!requestProfileTab.current) return;
        var tab = new bootstrap.Tab(requestProfileTab.current)
        tab.show()
    }
    const exploreMoreTabClicked = () => {
        setReloadSelectedProperty(true);
    }

    const onSelectedMapProperty = (parcelId) => {
        clearPropertyData();
        checkIsOverLimit()
            .then((overLimit) => {
                if (!overLimit) {
                    apiTools.get(`/property-profile?state=${selectedCounty.state}&county=${selectedCounty.countyValue}&parcelId=${parcelId}`)
                        .then((response) => {
                            setSelectedProperty(response.data);
                            logPropertyActivity(response.data);
                            syncSearchResults(response.data, selectedCounty.countyValue, selectedCounty.state);
                            showParcelInfoTab();
                        });
                }
            });
    }
   
    const clearPropertyData = () => {
        setReloadSelectedProperty(false);
        setDeedState({ deeds: [], loading: true });
        setCcrState({ ccrs: [], loading: true });
        setSelectedPropertyP1Data(emptyP1PropertyData);
    }

    const toggleSearchResults = () => {
        new bootstrap.Collapse(SearchResultsBody.current, {
            toggle: true
        })
    }

    const propertiesNearMeSubmit = (searchCriteria) => {
        setValue('searchCriteria', '');
        return onSubmit(searchCriteria);
    }

    const handleSearchCriteriaEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(onSubmit)();
        }
    }

    const handleCountyChange = () => { 
        clearPropertyData(); 
        setSelectedProperty(null);
        setSearchResults(null)
        setSearchResultsBatch(0);
        setSearchResultsCurrentCount(0);
        setSearchResultsTotalCount(0);
        setNearbySearchResults(null);
        setNearbySearchResultsTotalCount(0);
    }

    return (
        <>
            <Helmet>
                <title>PropertyOne : {selectedBrand.brandName}</title>
            </Helmet>   
            <BannerAd />
            <div className="modal fade" id="OverLimitModal" data-bs-backdrop="static" data-bs-keyboard="false" ref={OverLimitModal} tabIndex="-1" aria-labelledby="OverLimitModalLabel">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="OverLimitModalLabel">PropertyOne Limit Reached</h2>
                        </div>
                        <div className="modal-body">
                            <p>Thank you for using PropertyOne! You've reached your limit of {hasP1Gold ? '300' : '100'} profiles for this month. Your account will reset automatically on { DateTime.now().endOf('month').plus({days: 1}).toLocaleString(DateTime.DATE_MED)}</p>
                            {!hasP1Gold && <p>Want more profiles? Try <span data-bs-dismiss="modal" ><Link to="/preferred-customer/feature-payment">PropertyOne Gold</Link></span>.</p>}
                        </div>  
                        <div className="modal-footer">
                            <span data-bs-dismiss="modal" ><Link className="btn btn-primary" to="/tools">Close</Link></span>
                        </div>
                    </div>
                </div>
            </div>           
            <div>
                <h1 className="d-print-none">PropertyOne</h1>
                {<>
                    <div className="row d-print-none">
                        <div className="col-12 col-md-6">
                            <PropertiesNearMe onSubmit={propertiesNearMeSubmit} logActivity={logActivity} toolName="PropertyOne" title="PROPERTIES NEAR ME"  description="Use GPS to find property info near your current location."/>
                            <div className="card mb-2 mb-md-0">
                                <div className="card-header collapse-trigger" data-bs-toggle="collapse" data-bs-target="#SearchBody">
                                    <MdOutlineSearch className="sm-react-icons" /><strong className="ps-2">SEARCH CRITERIA</strong>
                                </div>
                                <div className="collapse show" id="SearchBody" ref={SearchBody}>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row pb-2">
                                                <div className="col-3 form-label fw-bold lh-sm align-top pt-2">
                                                    <label htmlFor="county">County:</label>
                                                </div>
                                                <div className="col-9">
                                                    <CountySelector regionId={activeRegion.regionId} selectedCounty={selectedCounty} setSelectedCounty={setSelectedCounty} toolName="PropertyOne" toolRoute="propertyone" additionalOnChange={() => handleCountyChange()} />
                                                </div>
                                            </div>
                                            <div className="row pb-2">
                                                <div className="col-3 form-label fw-bold lh-sm align-top pt-2">
                                                    <label htmlFor="searchType">Search By:</label>
                                                </div>
                                                <div className="col-9">
                                                    <select name="searchType" id="searchType" className="form-select" {...register("searchType")}>
                                                        <option value="siteAddr">Property address</option>
                                                        <option value="ownerNm">Owner Name</option>
                                                        <option value="parcelId">Parcel</option>
                                                        <option value="subdivision">Subdivision</option>
                                                        {activeRegion && activeRegion.regionId === 3 && <option value="taxAcctNum">AIN/Tax Account Number</option>}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row pb-2">
                                                <div className="col-3 form-label fw-bold lh-sm align-top pt-2">
                                                    <label htmlFor="searchCriteria">Search Criteria:</label>
                                                </div>
                                                <div className="col-9">
                                                    <input name="searchCriteria" type="text" id="searchCriteria" className="form-control" {...register("searchCriteria", { required: true })} onKeyDown={(e) => handleSearchCriteriaEnter(e)}/>
                                                    {errors?.searchCriteria?.type === "required" && <small className="text-danger">This field is required</small>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"></div>
                                                <div className="col-9">
                                                    <input type="submit" id="btnSearch" className="btn btn-primary" value="Search" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card">
                                <div className="card-header collapse-trigger" data-bs-toggle="collapse" data-bs-target="#RecentProps">
                                    <MdOutlineHistory className="sm-react-icons" /><strong className="ps-2">RECENT PROPERTIES</strong>
                                </div>
                                <div id="RecentProps" ref={RecentPropertiesPanel} className="collapse show">
                                    <div className="card-body">
                                        <RecentProperties onRecentlySelectedProperty={onRecentlySelectedProperty} selectedProperty={selectedProperty} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
                {searchCriteria && selectedCounty && <div className="card mt-3 d-print-none">
                    <div className="card-header collapse-trigger" data-bs-toggle="collapse" data-bs-target="#divSearchResults">
                        <MdOutlineHome className="sm-react-icons" />
                        <strong className="ps-2">SEARCH RESULTS AND MAP</strong>
                    </div>
                    <div className="collapse show" id="divSearchResults" ref={SearchResultsBody} >
                        <div className="card-body">
                            {loadingSearchResults && <SpinnerLoader />}
                            {!loadingSearchResults && searchResultsTotalCount === 0 && <span>No properties were found for <strong>{searchCriteria.searchCriteria}</strong> in <strong>{toTitleCase(selectedCounty.county)}</strong> County. Please verify that your search criteria is accurate.</span>}
                            {!loadingSearchResults && searchResultsTotalCount > 0 && (searchResultsTotalCount < 150 ? searchResultsTotalCount + " properties found" : "More than 150 properties found, please refine your search")}
                            <div className="row">
                                <div className="col-12 col-sm-6 properties-list order-sm-first order-last mt-4 mt-md-0" id="propertiesList">
                                    {searchResultsTotalCount > 0 && searchResults.map((result, index) => {
                                        return <SearchResult key={index} index={index} property={result} county={selectedCounty.county} expandedProperty={expandedProperty} setExpandedProperty={(w) => { setExpandedProperty(w) }} onSelectProperty={() => onSelectProperty(result)} />
                                    })}
                                    {loadingMoreResults && <SpinnerLoader />}
                                    {!loadingSearchResults && searchResultsTotalCount > 0 && nearbySearchResultsTotalCount === 0 && <div className="col-12 pt-2">
                                        <div>Showing {searchResultsCurrentCount} of {searchResultsTotalCount} total properties</div>
                                        {searchResultsCurrentCount === 150 && <div>Maximum results shown, please refine your search</div>}
                                        {(searchResultsCurrentCount !== searchResultsTotalCount) && <input type="button" value="Load More Results" className="btn btn-primary" onClick={() => loadMoreResults()} />}
                                    </div>}
                                    {!loadingSearchResults && nearbySearchResultsTotalCount > 0 && <div className="col-12 p-3">
                                        <div>{nearbySearchResultsTotalCount} nearby properties found</div>
                                        {nearbySearchResults.map((result, index) => {
                                            return <SearchResult key={"nearby_" + index} index={"nearby_" + index} property={result} county={selectedCounty.county} expandedProperty={expandedProperty} setExpandedProperty={(w) => { setExpandedProperty(w) }} onSelectProperty={() => onSelectProperty(result)} />
                                        })}
                                    </div>}
                                </div>
                                {!loadingSearchResults && searchResults && searchResultsTotalCount > 0 && <div className="col-12 col-sm-6 search-map" id="divSearchMap">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="legend-box legend-selected-property"></div><span className="align-top"> Selected Property </span>
                                        </div>
                                        <div>
                                            <div className="legend-box legend-nearby-property"></div><span className="align-top"> Nearby Search Result</span>
                                        </div>
                                        <div>
                                            <div className="legend-box legend-more-property"></div><span className="align-top"> Explore More</span>
                                        </div>
                                    </div>
                                    <ResultsMap id="searchResultsMap" parcelsToShowOnMap={resultsForMap} mapStateAbbrev={selectedCounty.state} mapCounty={selectedCounty.countyValue} setExpandedProperty={(w) => { setExpandedProperty(w) }} expandedProperty={expandedProperty} onSelectedMapProperty={onSelectedMapProperty} exploreMore={false} />
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>}
                {selectedProperty && selectedCounty && <>
                    <div className="row mt-2 mb-1">
                        <div className="col-10">
                            <h2 className="h3 mb-0 property-address">Property Address: <span className="no-localization">{selectedProperty.siteAddress != null ? selectedProperty.siteAddress + (selectedProperty.siteCity !== null ? ", " + selectedProperty.siteCity : "") : "To Be Determined"} </span></h2>
                        </div>
                        <div className="col-2 d-none d-print-block">
                            <img className="img-fluid float-end" src={FilePath('/preferredcustomerlogos/t1.png')} alt={selectedBrand.brandName + " Logo"} />
                        </div>
                    </div>
                    <ul className="nav nav-pills flex-column-2 mb-2 d-print-none" id="P1Tabs" role="tablist">
                        <li className="nav-item pe-2 ps-0" role="presentation">
                            <button className="nav-link active border border-primary p-2" id="parcelInfo-tab" ref={parcelInfoTab} data-bs-toggle="pill" data-bs-target="#parcelInfo" type="button" role="tab" aria-controls="parcelInfo" aria-selected="true">Parcel Info</button>
                        </li>
                        <li className="nav-item pe-2" role="presentation">
                            <button className="nav-link border border-primary p-2" id="deeds-tab" data-bs-toggle="pill" data-bs-target="#deeds" type="button" role="tab" aria-controls="deeds" aria-selected="false">Deeds</button>
                        </li>
                        <li className="nav-item pe-2" role="presentation">
                            <button className="nav-link border border-primary p-2" id="ccrs-tab" data-bs-toggle="pill" data-bs-target="#ccrs" type="button" role="tab" aria-controls="ccrs" aria-selected="false">CC&amp;Rs/Plats</button>
                        </li>
                        <li className="nav-item pe-2" role="presentation">
                            <button className="nav-link border border-primary p-2" id="locatorMaps-tab" data-bs-toggle="pill" data-bs-target="#locatorMaps" type="button" role="tab" aria-controls="locatorMaps" aria-selected="false">Locator Maps</button>
                        </li>
                        <li className="nav-item pe-2" role="presentation">
                            <button className="nav-link border border-primary p-2" id="streetMap-tab" data-bs-toggle="pill" data-bs-target="#streetMap" type="button" role="tab" aria-controls="streetMap" aria-selected="false">Street Map</button>
                        </li>
                        <li className="nav-item pe-2" role="presentation">
                            <button className="nav-link border border-primary p-2" id="exploreMore-tab" data-bs-toggle="pill" data-bs-target="#exploreMore" type="button" role="tab" aria-controls="exploreMore" aria-selected="false" onClick={exploreMoreTabClicked} >Explore More</button>
                        </li>
                        <li className="nav-item pe-2" role="presentation">
                            <button className="nav-link border border-primary p-2" id="addLogo-tab" data-bs-toggle="pill" data-bs-target="#addLogo" type="button" role="tab" aria-controls="addLogo" aria-selected="false">Add My Logo</button>
                        </li>
                        <li className="nav-item pe-2" role="presentation">
                            <button className="nav-link border border-primary p-2" id="downloadEmail-tab" data-bs-toggle="pill" data-bs-target="#downloadEmail" type="button" role="tab" aria-controls="downloadEmail" aria-selected="false">Download/Email</button>
                        </li>
                        <li className="nav-item pe-2" role="presentation">
                            <button className="nav-link border border-primary p-2" id="requestProfile-tab" ref={requestProfileTab} data-bs-toggle="pill" data-bs-target="#requestProfile" type="button" role="tab" aria-controls="requestProfile" aria-selected="false" onClick={() => EventTrack("PropertyOne", "Quick Profile Request Tab", "Direct")} >Request Profile</button>
                        </li>
                    </ul>
                    <div className="tab-content p-0 p-md-2 border rounded data-border" id="P1TabsContent">
                        <div className="tab-pane fade show active" id="parcelInfo" role="tabpanel" aria-labelledby="parcelInfo-tab"><ParcelInfo state={selectedCounty.state} county={toTitleCase(selectedCounty.county)} parcelId={selectedProperty.parcelId} setSelectedPropertyP1Data={setSelectedPropertyP1Data} selectedPropertyP1Data={selectedPropertyP1Data} setParcelHtml={(w) => { setParcelHtml(w) }}/>
                        </div>

                        <div className="tab-pane fade" id="deeds" role="tabpanel" aria-labelledby="deeds-tab"><Deeds pcUser={pcUser} activeRegion={activeRegion} selectedPropertyP1Data={selectedPropertyP1Data} deedState={deedState} setDeedState={setDeedState} logActivity={logActivity} showQuickProfileRequestTab={showQuickProfileRequestTab} />
                        </div>

                        <div className="tab-pane fade" id="ccrs" role="tabpanel" aria-labelledby="ccrs-tab"><CCRsPlatsHoa pcUser={pcUser} activeRegion={activeRegion} forSearchPage={false} selectedPropertyP1Data={selectedPropertyP1Data} logActivity={logActivity} ccrState={ccrState} setCcrState={setCcrState} folderGroupOpen={folderGroupOpen} setFolderGroupOpen={setFolderGroupOpen} showQuickProfileRequestTab={showQuickProfileRequestTab} />
                        </div>

                        <div className="tab-pane fade" id="locatorMaps" role="tabpanel" aria-labelledby="locatorMaps-tab"><LocatorMaps state={selectedCounty.state} county={toTitleCase(selectedCounty.county)} parcelId={selectedProperty.parcelId} selectedPropertyP1Data={selectedPropertyP1Data} setAerialMap={setAerialMap} aerialMap={aerialMap} setPlatMap={setPlatMap} platMap={platMap} setStreetLocatorMap={setStreetLocatorMap} streetLocatorMap={streetLocatorMap} />
                        </div>
                        
                        <div className="tab-pane fade" id="streetMap" role="tabpanel" aria-labelledby="streetMap-tab"><StreetMap property={selectedProperty} setStreetMapAvailable={setStreetMapAvailable} setDirections={setDirections} directions={directions} setPosition={setPosition} position={position} setOriginAddress={setOriginAddress} state={selectedCounty.state} selectedPropertyP1Data={selectedPropertyP1Data} />
                        </div>

                        <div className="tab-pane fade" id="exploreMore" role="tabpanel" aria-labelledby="exploreMore-tab"><ExploreMore regionId={activeRegion.regionId} countyId={selectedCounty.regionStateCountyId} state={selectedCounty.state} county={toTitleCase(selectedCounty.countyValue)} selectedPropertyP1Data={selectedPropertyP1Data} resultsForMap={resultsForMap} expandedProperty={expandedProperty} onSelectedMapProperty={onSelectedMapProperty} reloadSelectedProperty={reloadSelectedProperty} />
                        </div>

                        <div className="tab-pane fade" id="addLogo" role="tabpanel" aria-labelledby="addLogo-tab"><AddMyLogoTab />
                        </div>

                        <div className="tab-pane fade" id="downloadEmail" role="tabpanel" aria-labelledby="downloadEmail-tab"><DownloadEmailProfile parcelHtml={parcelHtml} pcUser={pcUser} selectedBrand={selectedBrand} activeRegion={activeRegion} ccrState={ccrState} folderGroupOpen={folderGroupOpen} setFolderGroupOpen={setFolderGroupOpen} deedState={deedState} aerialMap={aerialMap} streetLocatorMap={streetLocatorMap} platMap={platMap} streetMapAvailable={streetMapAvailable} directions={directions} position={position} address={selectedProperty.siteAddress} city={selectedProperty.siteCity} county={selectedCounty.countyValue} state={selectedCounty.state} parcelId={selectedProperty.parcelId} logActivity={logActivity} setOriginAddress={setOriginAddress} originAddress={originAddress} />
                        </div>

                        <div className="tab-pane fade" id="requestProfile" role="tabpanel" aria-labelledby="requestProfile-tab"><QuickProfileRequest pcUser={pcUser} activeRegion={activeRegion} selectedPropertyP1Data={selectedPropertyP1Data} logActivity={logActivity} />
                        </div>
                    </div>
                </>}

                <p className="d-print-none pt-2">By using PropertyOne you are accepting the terms of our <Link to="/terms-and-conditions">usage agreement</Link>. This tool works best in the Google Chrome browser. Need help? <a href={"mailto:" + ((activeRegion) ? activeRegion.stateAndCounties[0].csEmailAddress : "cservice@titleonecorp.com")}>Click here</a> to email us.</p>
                <p className="d-print-none"><strong>Want more profiles? Check out <Link to="/preferred-customer/feature-payment">PropertyOne Gold</Link>.</strong>
                </p>
                <p className="small">This report is based on a search of our tract indexes of the county records. This is not a title or ownership report and no examination of the title to the property described has been made. For this reason, no liability beyond the amount paid for this report is assumed hereunder, and the company is not responsible beyond the amount paid for any errors and omissions contained herein.
                </p>
               
            </div>
        </>
    );
}

export default PropertyOne;