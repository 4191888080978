import React from 'react';
import AddYourLogoForm from '../real-estate-pros/add-your-logo-form';

const AddMyLogoTab = () => {

    return (<>   
        <p>As a Preferred Customer you have the option of customizing Property Profiles by adding your own logo. Use the drop box below to upload your logo, then select "Add My Logo" on the Download/Email tab.</p>
        <AddYourLogoForm modal={false}/>
    </>
    );     
}

export default AddMyLogoTab;