import React, {  } from 'react';
import '../preferred-customer/profile.scss';
import "dropzone/dist/dropzone.css";
import AddYourLogoForm from './add-your-logo-form';

const AddYourLogoModal = ({setIncludeLogo}) => {
              
    return (
        <>
           <div className="modal" id="addLogoModal" tabIndex="-1" aria-labelledby="addLogoModalLabel" aria-hidden="true" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addLogoModalLabel">Add Your Logo</h5>
                        </div>
                        <div className="modal-body">
                            <AddYourLogoForm setIncludeLogo={setIncludeLogo} modal={true} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddYourLogoModal;