import React, { useState, useContext, useEffect } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import apiTools from '../../services/api-tools';
import SpinnerLoader from '../layout/spinner-loader';
import { MdOutlineImage, MdArrowBack, MdArrowUpward, MdOutlineMail, MdOutlineDownload } from 'react-icons/md';
import NetSheetComparisonSection from './net-sheet-comparison-section';
import currency from 'currency.js';
import { useForm } from "react-hook-form";
import fileDownload from 'js-file-download';
import AddYourLogoModal from './add-your-logo-modal';
import { UserContext } from '../../contexts/user-context';
import { FilePath, EventTrack, GetMultipleEmailRegex } from '../../services/utilities';

const NetSheetComparison = ({netSheets, setComparing, reverse}) => {
    const { pcUser, logActivity } = useContext(UserContext);
    const { selectedBrand } = useContext(BrandContext);
    const [sideShowing, setSideShowing] = useState("both");
    const [netSheetsWithData, setNetSheetsWithData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [netSheetEmailed, setNetSheetSent] = useState(false);
    const [sending, setSending] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [includeLogo, setIncludeLogo] = useState(null);
    const [disclaimer, setDisclaimer] = useState("");

    const [rowsToLeaveOut, setRowsToLeaveOut] = useState(["buyerName", "sellerName", "preparedBy", "propertyInsuranceYear", "propertyInsuranceMonth",
        "propertyCounty", "state", "propertyCity", "desiredProceeds", "mortgageBalance1", "mortgageBalance2", "common_totalMortgageDebt", "numberOfLoans", "ownersTitlePolicy",
        "buyersLoanAmount2", "interestRate2", "term2", "compoundingInterval2", "lendersTitlePolicySalesTax", "homeownersPolicySalesTax", "ownersTitlePolicySalesTax", "homeownersCoverageOnePolicySalesTax",
        "escrowFeeSalesTax", "eRecordingFeeSalesTax", "stateTechnologyFee", "stateExciseFee", "cityExciseFee", "buyer_taxServiceFee", "buyer_vaFundingFee",
        "courierFee", "wireFee", "buyersThirdPartyServiceFee", "buyersThirdPartyServiceFeeSalesTax", "eRecordingFee", "closingProtectionLetter", "buyer_appraisalFee", "seller_appraisalFee", "buyer_floodCertificationFee", "seller_floodCertificationFee", "seller_taxServiceFee",
        "loanType", "buyersDownPayment", "buyersDownPaymentPct", "buyersLoanAmount1", "interestRate1", "term1", "compoundingInterval1", "principal", 
        "buyer_hoaFeeCreditToSeller", "buyer_homeWarranty", "buyer_miscellaneous", "buyer_assistanceCredit", "endorsementsLabel", "buyer_secondHalfTaxesCredit",
        "buyer_miscellaneousCost1", "buyer_miscellaneousCostDescription1", "buyer_miscellaneousCost2", "buyer_miscellaneousCostDescription2", "buyer_miscellaneousCost3", "buyer_miscellaneousCostDescription3",
        "seller_secondMortgageBalance", 
        "seller_listingSideFlatFee", "seller_sellingSideFlatFee", "seller_homeownersCoverageOnePolicy", "buyer_homeownersPolicy", "buyer_homeownersPolicySalesTax", "seller_homeownersCoverageOnePolicySalesTax",
        "seller_assistanceToBuyer", "seller_homeOwnersAssociationCreditFromBuyer", "seller_homeWarranty", "seller_pestInspection", "seller_miscellaneousCost1", "seller_miscellaneousFee1", "seller_miscellaneousCost2", "seller_miscellaneousFee2", "seller_miscellaneousCost3", "seller_miscellaneousFee3", "seller_miscellaneousCost4", "seller_miscellaneousFee4",
        "sellersThirdPartyServiceFee", "sellersThirdPartyServiceFeeSalesTax", "buyer_secondHalfTaxesCredit", "seller_secondHalfTaxes"]);
    useEffect(() => {
        if (!netSheets) return;
        var promises = [];
        var netSheetDatas = [];
        setLoading(true);
        var arr = [...rowsToLeaveOut];
        var promise = null;
        for (var i = 0; i < netSheets.length; i++) {
            promise = apiTools.get(`/net-sheet/${netSheets[i].netSheetId}`);
            promises.push(promise);
        }
        Promise.all(promises)
            .then((responses) => {
                responses.forEach((response) => {
                    netSheetDatas.push({ ...response.data });
                    if (reverse === "reverse") {
                        arr = removeFromExcludeList(arr, ["desiredProceeds", "mortgageBalance1", "mortgageBalance2", "common_totalMortgageDebt", "numberOfLoans"]);
                    } else {
                        arr = removeFromExcludeList(arr, ["loanType", "buyersDownPayment", "buyersDownPaymentPct", "buyersLoanAmount1", "interestRate1", "term1", "compoundingInterval1", "principal"]);
                    }
                    if (response.data.buyerPaysHomeOwnersCoverageOnePolicy) {
                        arr = removeFromExcludeList(arr, ["buyer_homeownersPolicy", "buyer_homeownersPolicySalesTax"]);
                    } else {
                        arr = removeFromExcludeList(arr, ["seller_homeownersCoverageOnePolicy", "seller_homeownersCoverageOnePolicySalesTax"]);
                    }
                    if (!response.data.netSheetFormData.propertyCounty && response.data.netSheetFormData.county) response.data.netSheetFormData.propertyCounty = response.data.netSheetFormData.county;
                    if (response.data.netSheetFormData.propertyCounty && !response.data.netSheetFormData.county) response.data.netSheetFormData.county = response.data.netSheetFormData.propertyCounty.county;
                    if (response.data.netSheetFormData.propertyCounty.stateAbbr !== 'WA') {
                        arr = removeFromExcludeList(arr, ["ownersTitlePolicy"]);
                        if (response.data.salesTaxRate <= 0) arr = removeFromExcludeList(arr, ["ownersTitlePolicySalesTax"])
                    }
                    if (response.data.netSheetFormData.loanType === 'EightyTwenty') arr = removeFromExcludeList(arr, ["buyersLoanAmount2", "interestRate2", "term2", "compoundingInterval2"]);
                    if (response.data.salesTaxRate > 0) {
                        arr = removeFromExcludeList(arr, ["lendersTitlePolicySalesTax", "homeownersPolicySalesTax", "escrowFeeSalesTax",
                            "homeownersCoverageOnePolicySalesTax"])
                    }
                    if (response.data.chargeStateTechFee) arr = removeFromExcludeList(arr, ["stateTechnologyFee"]);
                    if (response.data.chargeBuyerTaxServiceFee) arr = removeFromExcludeList(arr, ["buyer_taxServiceFee"]);
                    if (response.data.chargeForCourier) arr = removeFromExcludeList(arr, ["courierFee"]);
                    if (response.data.chargeForWire) arr = removeFromExcludeList(arr, ["wireFee"]);
                    if (response.data.chargeBuyerThirdPartyServiceFee) {
                        arr = removeFromExcludeList(arr, ["buyersThirdPartyServiceFee"]);
                        if (response.data.salesTaxRate > 0) arr = removeFromExcludeList(arr, ["buyersThirdPartyServiceFeeSalesTax"]);
                    }
                    if (response.data.chargeSellerThirdPartyServiceFee) {
                        arr = removeFromExcludeList(arr, ["sellersThirdPartyServiceFee"]);
                        if (response.data.salesTaxRate > 0) arr = removeFromExcludeList(arr, ["sellersThirdPartyServiceFeeSalesTax"]);
                    }
                    if (response.data.chargeForeRecording) arr = removeFromExcludeList(arr, ["eRecordingFee"]);
                    if (response.data.chargeForClosingProtectionLetter) arr = removeFromExcludeList(arr, ["closingProtectionLetter"]);

                    if (response.data.buyersClosingCosts.appraisalFee !== 0) arr = removeFromExcludeList(arr, ["buyer_appraisalFee"]);
                    if (response.data.buyersClosingCosts.floodCertificationFee !== 0) arr = removeFromExcludeList(arr, ["buyer_floodCertificationFee"]);
                    if (response.data.buyersClosingCosts.vaFundingFee !== 0) arr = removeFromExcludeList(arr, ["buyer_vaFundingFee"]);

                    if (response.data.buyersMiscellaneousChargesAndCredits.hoaFeeCreditToSeller !== 0) arr = removeFromExcludeList(arr, ["buyer_hoaFeeCreditToSeller"]);
                    if (response.data.buyersMiscellaneousChargesAndCredits.homeWarranty !== 0) arr = removeFromExcludeList(arr, ["buyer_homeWarranty"]);
                    if (response.data.buyersMiscellaneousChargesAndCredits.miscellaneous !== 0) arr = removeFromExcludeList(arr, ["buyer_miscellaneous"]);
                    if (response.data.buyersMiscellaneousChargesAndCredits.assistanceCredit !== 0) arr = removeFromExcludeList(arr, ["buyer_assistanceCredit"]);
                    if (response.data.netSheetFormData.propertyCounty.stateAbbr !== 'OR') arr = removeFromExcludeList(arr, ["buyer_secondHalfTaxesCredit"]);
                    if (response.data.buyersMiscellaneousChargesAndCredits.miscellaneousCost1 !== 0 || response.data.buyersMiscellaneousChargesAndCredits.miscellaneousCostDescription1.replace("Misc Cost", "") !== "") arr = removeFromExcludeList(arr, ["buyer_miscellaneousCostDescription1", "buyer_miscellaneousCost1"]);
                    if (response.data.buyersMiscellaneousChargesAndCredits.miscellaneousCost2 !== 0 || response.data.buyersMiscellaneousChargesAndCredits.miscellaneousCostDescription2.replace("Misc Cost", "") !== "") arr = removeFromExcludeList(arr, ["buyer_miscellaneousCostDescription2", "buyer_miscellaneousCost2"]);
                    if (response.data.buyersMiscellaneousChargesAndCredits.miscellaneousCost3 !== 0 || response.data.buyersMiscellaneousChargesAndCredits.miscellaneousCostDescription3.replace("Misc Cost", "") !== "") arr = removeFromExcludeList(arr, ["buyer_miscellaneousCostDescription3", "buyer_miscellaneousCost3"]);

                    if (response.data.sellersMortgageInformation.secondMortgageBalance !== 0) arr = removeFromExcludeList(arr, ["seller_secondMortgageBalance"]);
                    if (response.data.chargeSellerTaxServiceFee) arr = removeFromExcludeList(arr, ["seller_taxServiceFee"]);
                    if (response.data.sellersCompensation.listingSideFlatFee !== 0) arr = removeFromExcludeList(arr, ["seller_listingSideFlatFee"]);
                    if (response.data.sellersCompensation.sellingSideFlatFee !== 0) arr = removeFromExcludeList(arr, ["seller_sellingSideFlatFee"]);
                    if (response.data.sellersClosingCosts.appraisalFee !== 0) arr = removeFromExcludeList(arr, ["seller_appraisalFee"]);
                    if (response.data.sellersClosingCosts.floodCertificationFee !== 0) arr = removeFromExcludeList(arr, ["seller_floodCertificationFee"]);
                    if (response.data.sellersClosingCosts.stateExciseFee > 0) arr = removeFromExcludeList(arr, ["stateExciseFee"]);
                    if (response.data.sellersClosingCosts.cityExciseFee > 0) arr = removeFromExcludeList(arr, ["cityExciseFee"]);

                    if (response.data.sellersMiscellaneousChargesAndCredits.assistanceToBuyer !== 0) arr = removeFromExcludeList(arr, ["seller_assistanceToBuyer"]);
                    if (response.data.sellersMiscellaneousChargesAndCredits.homeOwnersAssociationCreditFromBuyer !== 0) arr = removeFromExcludeList(arr, ["seller_homeOwnersAssociationCreditFromBuyer"]);
                    if (response.data.sellersMiscellaneousChargesAndCredits.homeWarranty !== 0) arr = removeFromExcludeList(arr, ["seller_homeWarranty"]);
                    if (response.data.sellersMiscellaneousChargesAndCredits.pestInspection !== 0) arr = removeFromExcludeList(arr, ["seller_pestInspection"]);
                    if (response.data.netSheetFormData.propertyCounty.stateAbbr !== 'OR') arr = removeFromExcludeList(arr, ["seller_secondHalfTaxes"]);
                    if (response.data.sellersMiscellaneousChargesAndCredits.miscellaneousFee1 !== 0 || response.data.sellersMiscellaneousChargesAndCredits.miscellaneousCost1.replace("Misc Cost", "") !== "") arr = removeFromExcludeList(arr, ["seller_miscellaneousCost1", "seller_miscellaneousFee1"]);
                    if (response.data.sellersMiscellaneousChargesAndCredits.miscellaneousFee2 !== 0 || response.data.sellersMiscellaneousChargesAndCredits.miscellaneousCost2.replace("Misc Cost", "") !== "") arr = removeFromExcludeList(arr, ["seller_miscellaneousCost2", "seller_miscellaneousFee2"]);
                    if (response.data.sellersMiscellaneousChargesAndCredits.miscellaneousFee3 !== 0 || response.data.sellersMiscellaneousChargesAndCredits.miscellaneousCost3.replace("Misc Cost", "") !== "") arr = removeFromExcludeList(arr, ["seller_miscellaneousCost3", "seller_miscellaneousFee3"]);
                    if (response.data.sellersMiscellaneousChargesAndCredits.miscellaneousFee4 !== 0 || response.data.sellersMiscellaneousChargesAndCredits.miscellaneousCost4.replace("Misc Cost", "") !== "") arr = removeFromExcludeList(arr, ["seller_miscellaneousCost4", "seller_miscellaneousFee4"]);

                    setLoading(false);
                    setNetSheetsWithData(netSheetDatas.sort((a, b) => a.netSheetId < b.netSheetId ? -1 : 1));
                    setRowsToLeaveOut(arr);
                });
            });
    }, [netSheets]);

    useEffect(() => {
        const getDisclaimer = async (propertyStateAbbr) => apiTools.get(`/net-sheet/disclaimer/${propertyStateAbbr}`)
            .then((resp) => {
                setDisclaimer(resp.data);
            });
        if (!netSheetsWithData || netSheetsWithData.length === 0) return;
        if (netSheetsWithData.find(ns => ns.netSheetFormData.propertyCounty.stateAbbr === "MT")) {
            getDisclaimer("MT");
        }
        else {
            getDisclaimer(netSheetsWithData[0].netSheetFormData.propertyCounty.stateAbbr);
        }

    }, [netSheetsWithData]);

    const removeFromExcludeList = (ogArray, rowArray) => {
        var arr = ogArray.filter(f => !rowArray.includes(f));
        return arr;
    }    

    const doDownload = () => {
        apiTools.post(`/net-sheet-comparison/download`, { htmlString: document.getElementsByTagName("html")[0].outerHTML }, { responseType: 'blob' })
            .then((response) => {
                fileDownload(response.data, `${selectedBrand.brandName.toLowerCase().replace(/ /g, "-")}-net-sheet-comparison.pdf`);
                if (reverse === "reverse") {
                    logActivity("Reverse Netsheet Comparison Printed", "");
                    EventTrack("Net Sheet List", "Reverse Net Sheet Comparison Downloaded");
                } else {
                    logActivity("Netsheet Comparison Printed", "");
                    EventTrack("Net Sheet List", "Net Sheet Comparison Downloaded");
                }
        });   
    }

    const onEmailSubmit = data => {
        setSending(true);
        const emailNetSheetComparisonObject = {
            brand: selectedBrand,
            to: data.to,
            html: document.getElementsByClassName("net-sheet-comparison-table")[0].outerHTML,
            notes: data.notes,
            includeLogo: includeLogo
        }
        const sendEmail = async () => {
            await apiTools.post(`/email-net-sheet-comparison`, emailNetSheetComparisonObject).then((response) => { setNetSheetSent(true); setSending(false); });
            if (reverse === "reverse") {
                logActivity("Reverse Netsheet Comparison Emailed", "");
                EventTrack("Net Sheet List", "Reverse Net Sheet Comparison Emailed");
            } else {
                logActivity("Netsheet Comparison Emailed", "");
                EventTrack("Net Sheet List", "Net Sheet Comparison Emailed");
            }
           
        }
        sendEmail();
    }        
    
    return (
        <>
            <h1>{(reverse === "reverse") && <>Reverse </>}Net Sheet Comparison</h1>
	        <div className="d-flex justify-content-around justify-content-lg-between flex-wrap d-print-none">
                <Link to="" onClick={() => setComparing(false)}><MdArrowBack className="sm-react-icons" />Back To Saved</Link>                                  
                <Link to="" data-bs-toggle="modal" data-bs-target="#emailNetSheetComparisonModal"><MdOutlineMail className="sm-react-icons" /> Email</Link>
                <Link to="" onClick={() => doDownload() }><MdOutlineDownload className="sm-react-icons" /> Download</Link>
                <Link to="" data-bs-toggle="modal" data-bs-target="#addLogoModal"><MdOutlineImage className="sm-react-icons" /> Add Your Logo</Link>
                <Link to={reverse === "reverse" ? "/reverse-net-sheet" : "/net-sheet"}><MdArrowUpward className="sm-react-icons" /> Back To Main</Link>
            </div>
            {reverse !== "reverse" && <div className="row py-2 d-print-none">
                <div className="col fw-bold">Show:</div>
                <div className="col">
                    <span className="form-control">
                        <label className="me-2"><input type="radio" className="me-2" checked={sideShowing === 'buyer'} onChange={() => setSideShowing("buyer")} />Buyer's Only </label>
                        <label  className="me-2"><input type="radio" className="me-2" checked={sideShowing === 'seller'} onChange={() => setSideShowing("seller")} />Seller's Only </label>
                        <label><input type="radio" className="me-2" checked={sideShowing === 'both'} onChange={() => setSideShowing("both")} />Both</label>
                    </span>
                </div>
            </div>}
            {loading && <SpinnerLoader />}
            {!loading && netSheets && netSheetsWithData && <div className="table-responsive net-sheet-comparison-table"><table className="w-100 table table-hover table-bordered table-sm">
                <tbody>
                    {pcUser && includeLogo && <tr className="d-none d-print-block border-0"><td className="border-0" colSpan={netSheets.length + 1}><img className="pt-2 pc-logo" alt="Preferred Customer Logo" src={FilePath('/preferredcustomerlogos/' + pcUser.logo)} /></td></tr>}
                    <tr className="fw-bold">
                        <th className="border-0"></th>
                        {netSheets.sort((a, b) => a.netSheetId < b.netSheetId ? -1 : 1).map((ns, i) => {
                            return <th className="border-0" key={"header_" + i}>
                                <Link className="p-0 no-localization d-print-none" to={((reverse === "reverse") ? "/reverse-net-sheet/" : "/net-sheet/") + ns.netSheetId}>{ns.netSheetName}</Link>
                                <span className="p-0 no-localization d-none d-print-inline">{ns.netSheetName}</span>
                                <br />{DateTime.fromISO(ns.lastSavedDate).toLocaleString()}
                                <br />{DateTime.fromISO(ns.lastSavedDate).toLocaleString(DateTime.TIME_SIMPLE)}
                            </th>
                        })}
                    </tr>
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="netSheetFormData" sectionName="Common Data" />

                    {(sideShowing === "both" || sideShowing === "buyer") && reverse !== "reverse" && <>< tr>
                        <td className="fw-bold border-email-bottom" colSpan={netSheets.length + 1} >
                           Buyer's Data
                        </td>
                    </tr>
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="buyerCommonData" sectionName="Common Data" />
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="buyersClosingCosts" sectionName="Closing Costs" />
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="buyersPrepaidExpenses" sectionName="Prepaid Expenses" />
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="buyersMiscellaneousChargesAndCredits" sectionName="Miscellaneous Charges & Credits" />
                    
                    <tr>
                        <td className="fw-bold border-email-bottom" colSpan={netSheets.length + 1} > Totals
                        </td>
                    </tr>
                    <tr>
                        <td className="fw-bold text-end">Total Cash Needed To Close:</td>
                        {netSheetsWithData.map((ns, i) => {
                            return <td key={"totalfield" + i}>{currency(ns.totalCashNeededToClose, { separator: ',', symbol: '$' }).format()}</td>
                        })}
                    </tr>
                    <tr>
                        <td className="fw-bold text-end">Notes:</td>
                        {netSheetsWithData.map((ns, i) => {
                            return <td key={"notesfield" + i}>{ns.buyerNotes}</td>
                        })}
                    </tr>

                    </>}

                    {(sideShowing === "both" || sideShowing === "seller") && <>< tr>
                        <td className="fw-bold border-email-bottom" colSpan={netSheets.length + 1} >
                           Seller's Data
                        </td>
                    </tr>
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="sellersMortgageInformation" sectionName="Mortgage Information" />
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="sellersClosingCosts" sectionName="Closing Costs" />
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="sellersCompensation" sectionName="Compensation" />
                    <NetSheetComparisonSection netSheets={netSheets} netSheetsWithData={netSheetsWithData} rowsToLeaveOut={rowsToLeaveOut} dataSection="sellersMiscellaneousChargesAndCredits" sectionName="Miscellaneous Charges & Credits" />

                    <tr>
                        <td className="fw-bold border-email-bottom" colSpan={netSheets.length + 1} > Totals
                        </td>
                    </tr>
                    <tr>
                        <td className="fw-bold text-end">Total Selling Expenses:</td>
                        {netSheetsWithData.map((ns, i) => {
                            return <td key={"totalsellingfield" + i}>{currency(ns.totalSellingExpenses, { separator: ',', symbol: '$' }).format()}</td>
                        })}
                    </tr>
                    <tr>
                        <td className="fw-bold text-end">Estimated Proceeds:</td>
                        {netSheetsWithData.map((ns, i) => {
                            return <td key={"proceeds" + i}>{currency(ns.estimatedProceeds, { separator: ',', symbol: '$' }).format()}</td>
                        })}
                    </tr>
                    <tr>
                        <td className="fw-bold text-end">Notes:</td>
                        {netSheetsWithData.map((ns, i) => {
                            return <td key={"sellernotesfield" + i}>{ns.sellerNotes}</td>
                        })}
                    </tr>
                    </>}
                </tbody>
            </table><p>{disclaimer}</p></div>}
            
            <div className="modal" id="emailNetSheetComparisonModal" tabIndex="-1" aria-labelledby="emailNetSheetComparisonModalLabel" aria-hidden="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="emailNetSheetComparisonModalLabel">Email Net Sheet Comparison</h5>
                        </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onEmailSubmit)}>
                            {netSheetEmailed && !sending && <p>Thank you, your net sheet comparison has been sent.</p>}
                            {!netSheetEmailed && !sending && <div className="row">
                                <div className="col-12 mt-2">
                                    <label className="fw-bold required">To address(es):</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <input type="text" className="form-control" id="emailNetSheetComparisonTo" {...register("to", { required: true, pattern: GetMultipleEmailRegex() })} />
                                    <small>Separate multiple addresses with a comma (,).</small>
                                    {errors?.to?.type === "required" && <small className="text-danger"><br/>This field is required</small>}
                                    {errors?.to?.type === "pattern" && <small className="text-danger"><br/>Invalid email address(es)</small>}
                                </div>
                                <div className="col-12 mt-2">
                                    <label className="fw-bold">Notes:</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <textarea id="emailNetSheetComparison" rows="3" className="form-control" {...register("notes")} ></textarea>
                                </div>
                            </div>}
                            {sending && <SpinnerLoader />}
                        <div className="modal-footer">
                            {!netSheetEmailed && <button type="submit" className="btn btn-primary m-auto">Send</button>}
                            <span onClick={() => setNetSheetSent(false)}><button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">{netSheetEmailed ? "Close" : "Cancel"}</button></span>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        <AddYourLogoModal toolName="Net Sheet Comparison" setIncludeLogo={setIncludeLogo} />
        </>
    );
}

export default NetSheetComparison;